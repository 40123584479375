import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Input from '../../components/Input';
import ButtonCustom from '../../components/Button';
import api from '../../instruments/api';
import styles from './style';
import Toast from '../../components/Toast';


// eslint-disable-next-line react/prop-types
const Login = ({ classes }) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [errorLoginToast, setErrorLoginToast] = useState(false);
  const loginUser = async () => {
  	let error = false;
	  if (!login) {
		  setLoginError(true);
		  error = true;
	  }
	  if (!password) {
		  setPasswordError(true);
		  error = true;
	  }
	  if (error) {
		  return;
	  }
	  try {
		  const response = await api.post('login', { email: login, password });
		  if (response.status === 401) {
			  setErrorLoginToast(true);
		  }
		  if (response.authorized && response.user.id) {
		  	localStorage.setItem('isLoggedIn', 'true');
		  	window.location.href = '/';
		  }
	  } catch (e) {
		  console.log(e);
	  }
  };
  return (
    <Box style={{ display: 'flex', height: '100vh' }}>
      {/* eslint-disable-next-line react/prop-types */}
      <Paper className={classes.Box}>
        <Toast
          open={errorLoginToast}
          closeToast={() => { setErrorLoginToast(false); }}
          message="Wrong email or password"
          type="error"
        />
        <Box p={3} mb={1} mt={3}>
          <Box mb={2}>
            <Input
              value={login}
              error={loginError}
              helperText="Wrong email"
              label="Login"
              onChange={({ target: { value } }) => setLogin(value)}
            />
          </Box>
          <Box mb={2}>
            <Input
              type="password"
              error={passwordError}
              helperText="Wrong password"
              value={password}
              label="Password"
              onChange={({ target: { value } }) => setPassword(value)}
            />
          </Box>
          <Box style={{
            display: 'flex',
	          justifyContent: 'center'
          }}
          >
            <ButtonCustom onClick={loginUser}>
              Login
            </ButtonCustom>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
export default withStyles(styles)(Login);

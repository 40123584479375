const types = {
  SET_SELECTED_CODE: 'SET_SELECTED_CODE',
  SET_CREATED_CODE: 'SET_CREATED_CODE',
  SET_POPUP_STATE: 'SET_POPUP_STATE',
  SET_SELECTED_ITEM: 'SET_SELECTED_ITEM',
  DELETED_COMPANY: 'DELETED_COMPANY',
  SET_DELETE_COMPANY_POPUP: 'SET_DELETE_COMPANY_POPUP',
  GET_COUNTRIES_LIST: 'GET_COUNTRIES_LIST',
};
export default types;

import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Logo from '../../assets/png/logo.png';
import useStyles from './style';

function ResponsiveDrawer(props) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  // eslint-disable-next-line react/prop-types
  const { window, location } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <div>
      <List className={classes.listMargin}>
        {['Dashboard', 'Receipts', 'Projects'].map((text) => (
          <ListItem
            button
            component={NavLink}
            to={`/${text.toLowerCase()}`}
            key={text}
            activeClassName={classes.active}
          >
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );
  const container = window !== undefined ? () => window().document.body : undefined;

  const AdminMenu = (
    <>
      <ListItem className={classes.dropDownListMenu} button onClick={handleClick}>
        <ListItemText primary="Admin" />
        {/* eslint-disable-next-line react/prop-types */}
        {open
        || location.pathname === '/codes'
        || location.pathname === '/overhead'
        || location.pathname === '/companies'
        || location.pathname === '/coproducer'
	        ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
				/* eslint-disable-next-line react/prop-types */
        in={open
        || location.pathname === '/codes'
        || location.pathname === '/overhead'
        || location.pathname === '/companies'
        || location.pathname === '/coproducer'}
        timeout="auto"
        unmountOnExit
      >
        <List
          component="div"
          className={classes.dropDownListMenu}
          disablePadding
        >
          <ListItem
            button
            style={{ marginLeft: 10 }}
            component={NavLink}
            to="/codes"
            exact
            activeClassName={classes.active}
          >
            <ListItemText primary="Codes" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/overhead"
            exact
            style={{ marginLeft: 10 }}
            activeClassName={classes.active}
          >
            <ListItemText primary="Overhead" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/companies"
            exact
            style={{ marginLeft: 10 }}
            activeClassName={classes.active}
          >
            <ListItemText primary="Companies" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/coproducer"
            exact
            style={{ marginLeft: 10 }}
            activeClassName={classes.active}
          >
            <ListItemText primary="Co-producers" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/countries"
            exact
            style={{ marginLeft: 10 }}
            activeClassName={classes.active}
          >
            <ListItemText primary="Countries" />
          </ListItem>
        </List>
      </Collapse>
    </>
  );
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
          {AdminMenu}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open
        >
          <img style={{ width: '80%', margin: '10px auto' }} src={Logo} alt="" />
          {drawer}
          {AdminMenu}
        </Drawer>
      </Hidden>
    </nav>
  );
}

ResponsiveDrawer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  window: PropTypes.func,
};

export default ResponsiveDrawer;

import { makeStyles } from '@material-ui/core/styles';


const styles = makeStyles(() => ({
  inputContainer: {
    display: 'flex',
    width: '60%',
    marginBottom: 15
  },
  rootDashboardContainer: {
    minWidth: 700
  },
  inputTypography: {
    minWidth: 100,
    maxWidth: 150,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  dashboardTitle: {
    color: '#aa4e9b',
    textAlign: 'left',
    marginBottom: 25
  }
}));
export default styles;

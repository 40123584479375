import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    borderColor: '#eeeeee',
  }
}));
export default styles;

import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './style';
import ReactVirtualizedTable from '../../../components/MUITable';
import api from '../../../instruments/api';
import { ContextApp } from '../../../contextStore/reducer';
import { actions } from '../../../contextStore/actions';

const CodesOverview = () => {
  const style = styles();
  const [tableData, setTableData] = useState([]);
  const { dispatch, state } = useContext(ContextApp);
  const TABLE_COLUMNS = [
    {
      width: 100,
      label: 'Code',
      dataKey: 'code',
    },
    {
      width: 300,
      label: 'Description',
      dataKey: 'title',
    },
    {
      width: 100,
      label: 'Elegible',
      dataKey: 'is_flanders',
    },
    {
      width: 100,
      label: 'Production',
      dataKey: 'is_tax_shelter',
    },
    {
      width: 100,
      label: 'Action',
      dataKey: 'action',
    },
  ];
  const makeInnerBtn = (item) => {
    return (
      <Tooltip title="Edit code" aria-label="add">
        <EditIcon
          style={{ cursor: 'pointer' }}
          onClick={() => {
					  dispatch(actions.setCode(item));
          }}
        />
      </Tooltip>
    );
  };
  const { selectedCode, createdCode } = state;
  useEffect(() => {
    if (Object.keys(createdCode).length) {
      const newArray = [...tableData, {
        ...createdCode,
        action: makeInnerBtn({
          id: createdCode.id,
          code: createdCode.code,
          title: createdCode.title,
          elegible: selectedCode.is_flanders,
          production: selectedCode.is_tax_shelter,
        })
      }];
      setTableData(newArray);
      dispatch(actions.setCreatedCodeToList({}));
    }
  }, [createdCode]);
  useEffect(() => {
    const copyOfDataArray = [...tableData];
    if (Object.keys(selectedCode).length && selectedCode.isChanged) {
      const changedItemIndex = tableData.findIndex((item) => item.id === selectedCode.id);
      delete selectedCode.isChanged;
      selectedCode.action = makeInnerBtn({
        id: selectedCode.id,
        code: selectedCode.code,
        title: selectedCode.title,
        elegible: selectedCode.is_flanders,
        production: selectedCode.is_tax_shelter,
      });
      copyOfDataArray.splice(changedItemIndex, 1, selectedCode);
      setTableData(copyOfDataArray);
    }
  }, [selectedCode]);
  useEffect(() => {
    api.get('codes').then((res) => {
      const { results } = res;
      const data = results.reduce((acc, current) => {
        const {
          children, code, title, id, is_flanders, is_tax_shelter,
        } = current;
        // eslint-disable-next-line no-shadow
        const array = children.map(({
          id, code, title, is_flanders, is_tax_shelter
        }) => ({
          code:code.toFixed(2),
          id,
          title,
          is_flanders: is_flanders ? 'Yes' : 'Non',
          is_tax_shelter: is_tax_shelter ? 'Yes' : 'Non',
          action: makeInnerBtn({
            id, code, title, elegible: is_flanders, production: is_tax_shelter
          })
        }));
        return [...acc, {
          code:code.toFixed(2),
          id,
          // eslint-disable-next-line camelcase
          title,
          is_flanders: is_flanders ? 'Yes' : 'Non',
          is_tax_shelter: is_tax_shelter ? 'Yes' : 'Non',
          action: makeInnerBtn({
            id, code, title, elegible: is_flanders, production: is_tax_shelter
          })
        }, ...array];
      }, []);
      setTableData(data);
    });
  }, [selectedCode]);
  return (
    <Box>
      <Paper>
        <Box className={style.rootDashboardContainer} p={5}>
          <Typography variant="h6" gutterBottom className={style.dashboardTitle}>
            Codes Overview
          </Typography>
          <ReactVirtualizedTable columns={TABLE_COLUMNS} data={tableData} />
        </Box>
      </Paper>
    </Box>
  );
};
export default CodesOverview;

import React from 'react';
import {
  Box, Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';

const ButtonCustom = ({
	                      onClick,
	                      disabled,
	                      // eslint-disable-next-line react/prop-types
	                      inputRef,
	                      children,
	                      classes,
	                      type
}) => {
  return (
    <Box>
      <Button
        className={classes.button}
        onClick={onClick}
        disabled={disabled}
        variant="contained"
        style={{
          color: (type === 'primary' ? '#656565' : 'white'),
          backgroundColor: (type === 'primary' ? 'rgb(234, 234, 234)' : 'rgba(170, 78, 155, 1)')
        }}
        size="small"
        innerRef={inputRef}
      >
        {children}
      </Button>
    </Box>
  );
};
ButtonCustom.defaultProps = {
  disabled: false,
  children: 'Button',
  type: 'default',
};
ButtonCustom.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
};
export default withStyles(styles)(ButtonCustom);

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { AutoSizer, Column, Table } from 'react-virtualized';
import Box from '@material-ui/core/Box';
import styles from './style';


class MuiVirtualizedTable extends React.PureComponent {
	// eslint-disable-next-line react/static-property-placement
	static defaultProps = {
	  headerHeight: 48,
	  rowHeight: 48,
	};

	getRowClassName = ({ index }) => {
	  const { classes, onRowClick, colourRows } = this.props;

	  return clsx(classes.tableRow, classes.flexContainer, {
	    [classes.tableRowHover]: index !== -1 && onRowClick != null,
	    [classes.greenRow]: colourRows ? colourRows.some((row) => row === index) : false,

	  });
	};

	cellRenderer = ({ cellData }) => {
	  const {
	    classes, rowHeight, onRowClick
	  } = this.props;
	  return (
  <TableCell
    component="div"
    className={clsx(classes.tableCell, classes.flexContainer, {
				  [classes.noClick]: onRowClick == null,
    })}
    variant="body"
    style={{ height: rowHeight, justifyContent: 'left' }}
  >
    {cellData}
      
  </TableCell>
	  );
	};

	headerRenderer = ({ label }) => {
	  const { headerHeight, classes } = this.props;

	  return (
  <TableCell
    component="div"
    className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
    variant="head"
    style={{ height: headerHeight }}
  >
    <span>{label}</span>
  </TableCell>
	  );
	};

	render() {
	  const {
	    classes, columns, rowHeight, headerHeight, ...tableProps
	  } = this.props;
	  return (
  <AutoSizer>
    {({ height, width }) => (
      <Table
        height={height}
        width={width}
        rowHeight={rowHeight}
        gridStyle={{
						  direction: 'inherit',
        }}
        headerHeight={headerHeight}
        className={classes.table}
        {...tableProps}
        rowClassName={this.getRowClassName}

      >
        {columns.map(({ dataKey, ...other }, index) => {
						  return (
  <Column
    key={dataKey}
    headerRenderer={
										(headerProps) => this.headerRenderer({
										  ...headerProps,
										  columnIndex: index,
										})
									}
    className={classes.flexContainer}
    cellRenderer={this.cellRenderer}
    dataKey={dataKey}
    {...other}
  />
						  );
        })}
      </Table>
    )}
  </AutoSizer>
	  );
	}
}
MuiVirtualizedTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
};
const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);


function ReactVirtualizedTable({ data, columns, colourRows }) {
  return (
    <Box  style={{ height: 'calc(100vh - 300px)', width: '100%' }}>
      <VirtualizedTable
        rowCount={data.length}
        rowGetter={({ index }) => data[index]}
        columns={columns}
        colourRows={colourRows}
        
      />
    </Box>
  );
}

ReactVirtualizedTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      title: PropTypes.string,
      action: PropTypes.object,
    })
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired
};
export default ReactVirtualizedTable;

import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '../../../components/Input';
import ButtonCustom from '../../../components/Button';
import styles from './style';
import api from '../../../instruments/api';
import { ContextApp } from '../../../contextStore/reducer';
import { actions } from '../../../contextStore/actions';
import Toast from '../../../components/Toast';

const CodesCreator = () => {
  const CODE_REGEX_TEST = /^\d+\.\d{2}$/;
  const style = styles();
  const { state, dispatch } = useContext(ContextApp);
  const [isEditing, setEditingToggle] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [description, setDescription] = useState('');
  const [elegible, setElegible] = useState('true');
  const [production, setProduction] = useState('true');
  const [toastType, setToastType] = useState('success');
  const [descriptionError, setDescriptionError] = useState(false);
  const [isToastOpened, setToastState] = useState(false);
  const [messageToast, setMessageToast] = useState('Code created successfully!');
  useEffect(() => {
    const { selectedCode } = state;
    if (Object.keys(selectedCode).length) {
      setCode(selectedCode.code.toFixed(2));
      setDescription(selectedCode.title);
      setElegible(`${selectedCode.elegible}`);
      setProduction(`${selectedCode.production}`);
      setEditingToggle(true);
      setDescriptionError(false);
      setCodeError(false);
    }
  }, [state]);
  const clearState = () => {
    setTimeout(() => {
      dispatch(actions.setCode({}));
      setCode('');
      setElegible('true');
      setProduction('true');
      setDescription('');
      setEditingToggle(false);
    }, 200);
  };

  async function setNewCode() {
    let error = false;
    if (!description) {
      error = true;
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
    }
    if (!code && !CODE_REGEX_TEST.test(code)) {
      error = true;
      setCodeError(true);
    } else {
      setCodeError(false);
    }
    if (error) {
      return;
    }
    try {
      if (!isEditing) {
        const response = await api.post('codes', {
          code,
          title: description,
          is_active: true,
          is_flanders: elegible,
          is_tax_shelter: production,
        });
        if (response && response.error) {
          setToastType('error');
          setMessageToast(response.error.message);
          setToastState(true);
        }
        if (response && response.code) {
          dispatch(actions.setCreatedCodeToList(response));
          setMessageToast('Code created successfully!');
          setToastState(true);
          setToastType('success');
          setDescriptionError(false);
          setCodeError(false);
          clearState();
        }
      } else {
        const { selectedCode: { id } } = state;
        const response = await api.put('codes', {
          code,
          title: description,
          is_active: true,
          is_flanders: elegible,
          is_tax_shelter: production,
        }, id);
        if (response && response.code) {
          setMessageToast('Code updated successfully!');
          setToastType('success');
          setToastState(true);
          setDescriptionError(false);
          setCodeError(false);
          clearState();
        }
        dispatch(actions.setCode({ ...response, isChanged: true }));
      }
    } catch (e) {
      // eslint-disable-next-line consistent-return
      return new Error(e);
    }
  }

  return (
    <>
      <Box mb={5}>
        <Paper>
          <Box className={style.rootDashboardContainer} p={5}>
            <Typography variant="h6" gutterBottom className={style.dashboardTitle}>
              Add Code
            </Typography>
            <Box className={style.inputContainer}>
              <Typography variant="subtitle1" className={style.inputTypography}>
                Code
              </Typography>
              <Input
                error={codeError}
                value={code}
                helperText="Code must contain only numbers"
                onChange={({ target: { value } }) => {
                  if (/^[0-9]*\.?[0-9]*$/.test(value)) {
                    setCode(value);
                  }
                }}
              />
            </Box>
            <Box className={style.inputContainer}>
              <Typography variant="subtitle1" className={style.inputTypography}>
                Description
              </Typography>
              <Input
                value={description}
                error={descriptionError}
                helperText="Description field can't be empty"
                onChange={({ target: { value } }) => setDescription(value)}
              />
            </Box>
            <Box className={style.inputContainer}>
              <Typography variant="subtitle1" className={style.inputTypography}>
                Elegible
              </Typography>
              <RadioGroup row aria-label="position" name="is_flanders" value={elegible} onChange={(e) => setElegible(e.target.value)}>
                <FormControlLabel
                  value="true"
                  control={<Radio className={style.radio} color="primary" />}
                  label="Yes"
                  labelPlacement="start"
                />
                <FormControlLabel value="false" control={<Radio className={style.radio} color="primary" />} labelPlacement="start" label="Non" />
              </RadioGroup>
            </Box>
            <Box className={style.inputContainer}>
              <Typography variant="subtitle1" className={style.inputTypography}>
                Production
              </Typography>
              <RadioGroup row aria-label="position" name="is_tax_shelter" value={production} onChange={(e) => setProduction(e.target.value)}>
                <FormControlLabel
                  value="true"
                  control={<Radio className={style.radio} color="primary" />}
                  label="Yes"
                  labelPlacement="start"
                />
                <FormControlLabel value="false" control={<Radio className={style.radio} />} labelPlacement="start" label="Non" />
              </RadioGroup>
            </Box>
            <Box>
              <ButtonCustom onClick={setNewCode}>Save</ButtonCustom>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Toast
        open={isToastOpened}
        closeToast={() => {
          setToastState(false);
        }}
        message={messageToast}
        type={toastType}
      />
    </>
  );
};
export default CodesCreator;

import React from 'react';
import {
  TextField
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';

const Input = ({
  onChange,
  disabled,
  value,
  // eslint-disable-next-line react/prop-types
  inputRef,
  label,
  type,
  // eslint-disable-next-line react/prop-types
  classes,
  helperText,
  error,
  multiline,
}) => {
  return (
    <TextField
        /* eslint-disable-next-line react/prop-types */
      className={classes.textField}
      fullWidth
      variant="outlined"
      multiline={multiline}
      label={label}
      name="text"
      error={error}
      size="small"
      helperText={error && helperText ? helperText : null}
      type={type}
      onChange={onChange}
      inputRef={inputRef}
      disabled={disabled}
      InputProps={{
        // eslint-disable-next-line react/prop-types
        className: classes.input,
      }}
      value={value}
    />
  );
};
Input.defaultProps = {
  disabled: false,
  value: '',
  label: '',
  type: 'text',
  helperText: '',
  error: false,
  multiline: false,
};
Input.propTypes = {
  multiline: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
};

export default withStyles(styles)(Input);

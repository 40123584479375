const styles = {
  textField: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    border: 'none',
    borderColor: '#eeeeee !important',
    color: 'gray',
  },
  input: {
    color: 'gray',
    borderColor: '#eeeeee',
  }
};

export default styles;

const styles = {
  button: {
    backgroundColor: '#aa4e9b',
    border: 0,
    borderRadius: 6,
    color: 'white',
    height: 40,
    padding: '0 40px',
    display: 'block',
    '&:focus': {
      backgroundColor: '#854376',
    },
    '&:hover': {
      backgroundColor: '#854376',
    }
  }
};

export default styles;

const config = {
  baseURL: process.env.REACT_APP_BASE_URL,
  endpoints: {
    login: 'auth/login',
    codes: 'codes',
    companies: 'companies',
    countries: 'countries',
    companiesChangeStatus: ['companies', 'change-status'],
    projectsChangeStatus: ['projects', 'change-status'],
    projects: 'projects',
    files: 'files/upload',
    receipts: 'receipts',
    receiptsChangePaymentStatus: ['receipts', 'change-is-payed'],
    projectsDetails: ['projects', 'detail'],
    projectsBudget: ['projects', 'budget'],
    projectsFinancing: ['projects', 'financing', 'change-is-confirmed'],
	  projectsReport: ['projects', 'reports'],
	  overhead: 'overhead',
  },
};
const buildUrl = (endpoint = '', params = {}, id = '') => {
  console.log(`${config.baseURL}${config.endpoints[endpoint]}`);
  const url = new URL(`${config.baseURL}${config.endpoints[endpoint]}`);
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  return url.pathname + (id ? (`/${id}`) : '') + url.search;
};

async function isUserLoggedInMiddleware(response) {
  if (response.status === 401) {
    localStorage.removeItem('isLoggedIn');
    if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    } else {
      return response;
    }
  }
  return response.json();
}

const api = {
  get: async (endpoint = '', params = '', secondId = '', id = '') => {
    try {
      const url = buildUrl(endpoint, params, id);
      let rebuildsUrl;
      if (Array.isArray(config.endpoints[endpoint])) {
        rebuildsUrl = `/api/${config.endpoints[endpoint][0]}/${params}/${config.endpoints[endpoint][1]}/${secondId}`;
      } else {
        rebuildsUrl = `${url}`;
      }
      const response = await fetch(rebuildsUrl, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
      return isUserLoggedInMiddleware(response);
    } catch (e) {
      return e;
    }
  },
  getById: async (endpoint = '', params = '') => {
    try {
      const response = await fetch(`/api/${endpoint}/${[params]}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
      return isUserLoggedInMiddleware(response);
    } catch (e) {
      return e;
    }
  },
  post: async (endpoint, body, params = {}) => {
    try {
     
      const url = buildUrl(endpoint, params);
      console.log(url);
      let rebuildsUrl;
      if (Array.isArray(config.endpoints[endpoint])) {
        rebuildsUrl = `/api/${config.endpoints[endpoint][0]}/${params}/${config.endpoints[endpoint][1]}`;
      } else {
        rebuildsUrl = `${url}`;
      }
      
      const response = await fetch(rebuildsUrl, {
        method: 'POST',
        body: JSON.stringify({ ...body }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
      return isUserLoggedInMiddleware(response);
    } catch (e) {
      console.error(e);
      return e;
    }
  },
  attachCoProducerProject: async (body, id, action) => {
    try {
      const response = await fetch(`/api/projects/${id}/co-producer/${action}`, {
        method: 'POST',
        body: JSON.stringify({ ...body }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
      return isUserLoggedInMiddleware(response);
    } catch (e) {
      return e;
    }
  },
  attachCountryProject: async (body, id, action) => {
    try {
      const response = await fetch(`/api/projects/${id}/country/${action}`, {
        method: 'POST',
        body: JSON.stringify({ ...body }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
      return isUserLoggedInMiddleware(response);
    } catch (e) {
      return e;
    }
  },
  put: async (endpoint, body, pathEndpoint = {}) => {
    try {
      const url = buildUrl(endpoint, {});
      const response = await fetch(`${url}/${pathEndpoint}`, {
        method: 'PUT',
        body: JSON.stringify({ ...body }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
      return isUserLoggedInMiddleware(response);
    } catch (e) {
      return e;
    }
  },
  putWidthTwoParams: async (endpoint, body, idFirst, idSecond) => {
    try {
      const response = await fetch(
        `/api/${config.endpoints[endpoint][0]}/${idFirst}/${config.endpoints[endpoint][1]}/${idSecond}`, {
          method: 'PUT',
          body: JSON.stringify({ ...body }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      return isUserLoggedInMiddleware(response);
    } catch (e) {
      return e;
    }
  },
  delete: async (endpoint, params = {}) => {
    try {
      const url = buildUrl(endpoint, {});
      const response = await fetch(`${url}/${params}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
      return isUserLoggedInMiddleware(response);
    } catch (e) {
      return e;
    }
  },
  deleteWidthTwoParams: async (endpoint, idFirst, idSecond) => {
    try {
      const response = await fetch(
        `/api/${config.endpoints[endpoint][0]}/${idFirst}/${config.endpoints[endpoint][1]}/${idSecond}`, {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      return isUserLoggedInMiddleware(response);
    } catch (e) {
      return e;
    }
  },
  patch: async (endpoint, body, idFirst, idSecond = '') => {
    try {
      const response = await fetch(
	      // eslint-disable-next-line max-len
        `/api/${config.endpoints[endpoint][0]}/${idFirst}/${config.endpoints[endpoint][1]}/${config.endpoints[endpoint][2] ? `${config.endpoints[endpoint][1]}/${idSecond}` : ''}`,
        {
          method: 'PATCH',
          body: JSON.stringify({ ...body }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      return isUserLoggedInMiddleware(response);
    } catch (e) {
      return e;
    }
  },
  postFiles: async (endpoint, body, params = {}) => {
    try {
      const url = buildUrl(endpoint, params);
      const response = await fetch(url, {
        method: 'POST',
        body,
      });
      return isUserLoggedInMiddleware(response);
    } catch (e) {
      return e;
    }
  },
};
export default api;

import types from './types';

export const actions = {
  setCode: (selectedCode) => {
    return {
      type: types.SET_SELECTED_CODE,
      payload: selectedCode
    };
  },
  setCreatedCodeToList: (createdCode) => {
    return {
      type: types.SET_CREATED_CODE,
      payload: createdCode
    };
  },
  setPopupState: () => {
    return {
      type: types.SET_POPUP_STATE,
    };
  },
  setDeletedCompany: (company) => {
    return {
      type: types.DELETED_COMPANY,
      payload: company
    };
  },
  openCloseDeleteCompanyPopup: () => {
    return {
      type: types.SET_DELETE_COMPANY_POPUP,
    };
  },
  getCountries: (countries) => {
    return {
      type: types.GET_COUNTRIES_LIST,
      payload: countries,
    };
  },
  selectItem: (data) => {
    return {
      type: types.SET_SELECTED_ITEM,
      payload: data
    };
  },
};

import React from 'react';
import types from './types';

export const ContextApp = React.createContext(null);

export const initialState = {
  selectedCode: {},
  createdCode: {},
  isPopupOpened: false,
  selectedItem: {},
  deletedItem: {},
  isDeleteCompany: false,
  countries: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SELECTED_CODE:
      return {
        ...state,
        selectedCode: action.payload
      };
    case types.SET_CREATED_CODE:
      return {
        ...state,
        createdCode: action.payload
      };
    case types.SET_POPUP_STATE:
      return {
        ...state,
        isPopupOpened: !state.isPopupOpened,
      };
    case types.SET_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.payload
      };
    case types.DELETED_COMPANY:
      return {
        ...state,
        deletedItem: action.payload
      };
    case types.GET_COUNTRIES_LIST:
      return {
        ...state,
        countries: action.payload
      };
    case types.SET_DELETE_COMPANY_POPUP:
      return {
        ...state,
        isDeleteCompany: !state.isDeleteCompany,
      };
    default:
      return state;
  }
};

import React from 'react';
import Box from '@material-ui/core/Box';
import styles from './style';
import ResponsiveDrawer from '../../components/Drawer';


const Dashboard = (props) => {
  const classes = styles();
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  return (
    <div className={classes.root}>
      <Box>
        <ResponsiveDrawer {...props} />
      </Box>
      <Box p={5}>
        {children}
      </Box>
    </div>
  );
};
export default Dashboard;
